import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Flip, ToastContainer } from 'react-toastify';
import './App.css';
import { LangSwitcherProvider } from './context/lang-switcher';
import 'react-toastify/dist/ReactToastify.css';
import "flatpickr/dist/themes/airbnb.css";
import { AppRoutes } from './Routes';
import { RoleSwitcherProvider } from './context/role-switcher';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LangSwitcherProvider>
        <RoleSwitcherProvider>
          <AppRoutes />
          <ToastContainer
            position='top-right'
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false} //rtl
            pauseOnHover
            transition={Flip}
          />
        </RoleSwitcherProvider>
      </LangSwitcherProvider>
    </QueryClientProvider>
  );
}

export default App;
