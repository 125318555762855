import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  createAppointment,
  getAppointmentDetails,
  updateAppointment,
} from '../../../apis/appointments';
import Header from '../../../components/layout/header';
import { getRouteLink, t } from '../../../helpers/lang-helper';
import { toast } from 'react-toastify';
import moment from 'moment';
import CustomButton from '../../../components/customButton/custom-button';
import CustomSpinner from '../../../components/spinner/spinner';
import Flatpickr from 'react-flatpickr';
import { RoleSwitcher } from '../../../context/role-switcher';

export const AppointmentForm = ({ formType }) => {
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const { role } = useContext(RoleSwitcher);
  const appointmentId = params.get('id');
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formValues, setFormValues] = useState({
    subject: '',
    organization: '',
    location: '',
    description: '',
    reminderDate: '',
    reminderTime: '',
    scheduledDate: '',
    scheduledTime: '',
  });
  const createAppointmentMutation = useMutation(createAppointment);
  const updateAppointmentMutation = useMutation(updateAppointment);
  const { isFetching, refetch } = useQuery(
    ['appointmentDetails', { id: appointmentId }],
    getAppointmentDetails,
    {
      onSuccess: (data) => {
        if (!data?.data?.[0] && formType === 'update') {
          navigate(getRouteLink('/appointments'));
        }
        setFormInitialValues(data?.data?.[0]);
      },
      enabled: formType === 'update',
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    if (role !== 'Secretary') {
      navigate(getRouteLink('/appointments'));
    }
  }, [role]); //eslint-disable-line

  const setFormInitialValues = (data) => {
    setFormValues({
      subject: data?.subject,
      organization: data?.organization,
      location: data?.location,
      description: data?.details,
      reminderDate: data?.reminder
        ? moment(data?.reminder).toISOString()
        : undefined,
      reminderTime: data?.reminder
        ? moment(data?.reminder).toISOString()
        : undefined,
      scheduledDate: data?.scheduled_date
        ? moment(data?.scheduled_date).toISOString()
        : undefined,
      scheduledTime: data?.scheduled_date
        ? moment(data?.scheduled_date).toISOString()
        : undefined,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitted(true);
    const form = e.currentTarget;
    let hasError = false;
    if (
      (formValues.reminderTime && !formValues.reminderDate) ||
      (!formValues.reminderTime && formValues.reminderDate) ||
      (formValues.scheduledTime && !formValues.scheduledDate) ||
      (!formValues.scheduledTime && formValues.scheduledDate)
    ) {
      hasError = true;
    }
    if (form.checkValidity() !== false && !hasError) {
      const scheduledDateFormat = moment(formValues.scheduledDate).format(
        'YYYY-MM-DD'
      );
      const reminderDateFormat = moment(formValues.reminderDate).format(
        'YYYY-MM-DD'
      );
      const scheduledTimeFormat = moment(formValues.scheduledTime).format(
        'HH:mm'
      );
      const reminderTimeFormat = moment(formValues.reminderTime).format(
        'HH:mm'
      );
      const requestBody = {
        subject: formValues.subject,
        details: formValues.description,
        scheduled_date: `${scheduledDateFormat} ${scheduledTimeFormat}:00`,
        organization: formValues.organization,
        location: formValues.location,
        reminder: null
      };
      if (
        formValues.reminderDate &&
        formValues.reminderTime
      ) {
        requestBody.reminder = `${reminderDateFormat} ${reminderTimeFormat}:00`;
      }
      if (formType === 'update') {
        handleEditMutation(requestBody);
      } else if (formType === 'create') {
        handleCreateMutation(requestBody);
      }
    }
    setValidated(true);
  };

  const handleCreateMutation = (requestBody) => {
    createAppointmentMutation.mutate(requestBody, {
      onSuccess: (data) => {
        toast.success(data?.meta?.detail);
        navigate(getRouteLink('/appointments?tab=added'));
      },
      onError: (error, variables, context) => {
        // console.log(error, variables, context)
      },
    });
  };

  const handleEditMutation = (requestBody) => {
    requestBody.action = 'edit';
    updateAppointmentMutation.mutate(
      { requestBody, id: appointmentId },
      {
        onSuccess: (data) => {
          toast.success(data?.meta?.detail);
          navigate(getRouteLink('/appointments?tab=added'));
        },
        onError: (error, variables, context) => {
          // console.log(error, variables, context)
        },
      }
    );
  };

  return (
    <>
      {(isFetching || (loading && formType === 'update')) && <CustomSpinner />}
      <Header refetch={refetch} setLoading={setLoading}></Header>
      <div className='newAppoinmentForm'>
        <div className='container'>
          <div className='innerWrapper'>
            <h2
              className='formTitle'
              onClick={() => navigate(getRouteLink('/appointments'))}
            >
              {formType === 'update'
                ? t('updateAppointmentHeading')
                : t('createAppointmentHeading')}
            </h2>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className=''>
                <Form.Group controlId='validationCustomSubject'>
                  <InputGroup hasValidation>
                    <Form.Control
                      type='text'
                      placeholder={t('subjectText')}
                      aria-describedby='inputGroupPrepend'
                      required
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          subject: e.target.value,
                        });
                      }}
                      defaultValue={formValues.subject}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {t('pleaseAddSubject')}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className=''>
                <Form.Group controlId='validationCustomOrganization'>
                  <InputGroup hasValidation>
                    <Form.Control
                      type='text'
                      placeholder={t('organizationText')}
                      aria-describedby='inputGroupPrepend'
                      required
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          organization: e.target.value,
                        });
                      }}
                      defaultValue={formValues.organization}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {t('pleaseAddOrganization')}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className=''>
                <Form.Group controlId='validationCustomLocation'>
                  <InputGroup hasValidation>
                    <Form.Control
                      type='text'
                      placeholder={t('locationText')}
                      aria-describedby='inputGroupPrepend'
                      required
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          location: e.target.value,
                        });
                      }}
                      defaultValue={formValues.location}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {t('pleaseAddLocation')}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className=''>
                <Form.Group controlId='validationCustomDescription'>
                  {/* <Form.Label>{t('descriptionText')}</Form.Label> */}
                  <InputGroup hasValidation>
                    <Form.Control
                      style={{ paddingTop: '20px' }}
                      as='textarea'
                      placeholder={t('descriptionText')}
                      aria-describedby='inputGroupPrepend'
                      required
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          description: e.target.value,
                        });
                      }}
                      defaultValue={formValues.description}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {t('pleaseAddDescription')}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className='doubleRow'>
                <Form.Group controlId='validationCustomDate'>
                  <Form.Label>{t('dateText')}</Form.Label>
                  <Flatpickr
                    className={`${isSubmitted && !formValues.scheduledDate
                      ? 'picker-invalid'
                      : ''
                      }`}
                    placeholder='dd/mm/yyyy'
                    value={formValues.scheduledDate}
                    required
                    options={{ dateFormat: 'd/m/Y' }} //minDate: 'today'
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        scheduledDate: e[0],
                      });
                    }}
                  />
                  {isSubmitted && !formValues.scheduledDate && (
                    <div className='custom-invalid-feedback'>
                      {t('pleaseAddDate')}
                    </div>
                  )}
                </Form.Group>
                <Form.Group controlId='validationCustomTime'>
                  <Form.Label>{t('timeText')}</Form.Label>
                  <Flatpickr
                    className={`${isSubmitted && !formValues.scheduledTime
                      ? 'picker-invalid'
                      : ''
                      }`}
                    value={formValues.scheduledTime}
                    placeholder='HH:mm'
                    required
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      time_24hr: true,
                    }}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        scheduledTime: e[0],
                      });
                    }}
                  />
                  {isSubmitted && !formValues.scheduledTime && (
                    <div className='custom-invalid-feedback'>
                      {t('pleaseAddTime')}
                    </div>
                  )}
                </Form.Group>
              </Row>
              <Form.Label className='reminderTitle'>
                {`${t('reminderText')} (${t('optionalText')})`}
              </Form.Label>
              <Row className='doubleRow'>
                <Form.Group>
                  <Form.Label>{t('dateText')}</Form.Label>
                  <Flatpickr
                    className={`${isSubmitted &&
                      formValues.reminderTime &&
                      !formValues.reminderDate
                      ? 'picker-invalid'
                      : ''
                      }`}
                    placeholder='dd/mm/yyyy'
                    required
                    options={{
                      dateFormat: 'd/m/Y',
                      // maxDate: formValues.scheduledDate,
                      // minDate: 'today',
                    }}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        reminderDate: e[0],
                      });
                    }}
                    value={formValues.reminderDate}
                  />
                  {isSubmitted &&
                    formValues.reminderTime &&
                    !formValues.reminderDate && (
                      <div className='custom-invalid-feedback'>
                        {t('pleaseAddDate')}
                      </div>
                    )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('timeText')}</Form.Label>
                  <Flatpickr
                    className={`${isSubmitted &&
                      !formValues.reminderTime &&
                      formValues.reminderDate
                      ? 'picker-invalid'
                      : ''
                      }`}
                    value={formValues.reminderTime}
                    required
                    placeholder='HH:mm'
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      time_24hr: true,
                    }}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        reminderTime: e[0],
                      });
                    }}
                  />
                  {isSubmitted &&
                    !formValues.reminderTime &&
                    formValues.reminderDate && (
                      <div className='custom-invalid-feedback'>
                        {t('pleaseAddTime')}
                      </div>
                    )}
                </Form.Group>
              </Row>

              {formType === 'update' ? (
                <CustomButton
                  type='submit'
                  loading={updateAppointmentMutation.isLoading}
                  text={t('updateText')}
                />
              ) : (
                <CustomButton
                  type='submit'
                  loading={createAppointmentMutation.isLoading}
                  text={t('createText')}
                />
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
