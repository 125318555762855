/** @format */

import DatepickerIcon from '../../assets/images/dateIcon.svg';
import Header from '../../components/layout/header';
import { useNavigate } from 'react-router-dom';
import { getRouteLink, t } from '../../helpers/lang-helper';
import { useQuery } from '@tanstack/react-query';
import {
  getAppointmentStatus,
  getAppointmentsByStatus,
} from '../../apis/appointments';
import { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import AppointmentTable from './components/appointment-table';
import { Button, Form, Overlay, Popover } from 'react-bootstrap';
import noDataImg from '../../assets/images/no-data-img.png';
import CustomSpinner from '../../components/spinner/spinner';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/airbnb.css';
import { LangSwitcher } from '../../context/lang-switcher';
import { RoleSwitcher } from '../../context/role-switcher';
import SettingsIcon from '../../assets/images/settings-icon.svg'

export const appointmentStatusArray = [
  {
    id: 1,
    code: 'ready',
    locateCode: 'readyText',
    value: 'Ready',
  },
  {
    id: 2,
    code: 'scheduled',
    locateCode: 'scheduledText',
    value: 'Scheduled',
  },
  {
    id: 3,
    code: 'added',
    locateCode: 'addedText',
    value: 'Added',
  },
  {
    id: 4,
    code: 'archive',
    locateCode: 'archiveText',
    value: 'Archive',
  },
];
export const Appointments = () => {
  const params = new URLSearchParams(window.location.search);
  const tab = params.get('tab');
  let navigate = useNavigate();
  const { lang } = useContext(LangSwitcher);
  const { role } = useContext(RoleSwitcher);
  const [filterStatusId, setFilterStatusId] = useState('all');
  const [startDate, setStartDate] = useState(moment().toISOString());
  const [endDate, setEndDate] = useState(moment().toISOString());
  const [appointmentTab, setAppointmentTab] = useState('ready');
  const [appointmentsDataArray, setAppointmentsDataArray] = useState([]);
  const [TabCounts, setTabCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [refetchingInterval, setRefetchingInterval] = useState(5000);
  const getStatus = useQuery(['status'], getAppointmentStatus);
  const popoverRef = useRef(null);
  const fields = [
    { value: 'subject', label: t('subjectText') },
    { value: 'organization', label: t('organizationText') },
    { value: 'location', label: t('locationText') },
    { value: 'description', label: t('descriptionText') },
    { value: 'file', label: t('fileText') },
    { value: 'schedule_date', label: t('scheduledDateText') },
    { value: 'reminder', label: t('reminderText') },
    { value: 'actions', label: t('actionsText') },
    { value: 'status', label: t('statusText') }
  ];
  const [activeFields, setActiveFields] = useState(
    fields.map((field) => field.value)
  );
  const [show, setShow] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    document &&
      document.addEventListener('click', (e) => {
        const className = e.target.className;
        if (
          typeof className === 'string' &&
          !className.includes('popover-ignore') &&
          className !== 'popover-ignore'
        ) {
          setShow(false);
        }
      });
  });

  useEffect(() => {
    const fields = (localStorage.getItem("activeFields"))?.split(",")
    if (fields?.length)
      setActiveFields(fields)
  }, [])

  const formatScheduleDate = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    return `${start}_${end}`;
  };

  const checkDateValidation = () => {
    if (
      moment(endDate).format('YYYY-MM-DD') <
      moment(startDate).format('YYYY-MM-DD')
    ) {
      setEndDate(startDate);
      // toast.error("Please enter valid end date")
      // return false
    }
    return true;
  };
  useEffect(() => {
    if (tab) {
      setAppointmentTab(tab);
      navigate(getRouteLink('/appointments'));
    }
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const { refetch } = useQuery(
    [
      'AppointmentByStatus',
      {
        appointmentStatus: appointmentTab,
        status_id: filterStatusId,
        scheduled_date: formatScheduleDate(startDate, endDate),
      },
    ],
    getAppointmentsByStatus,
    {
      onSuccess: (data) => {

        setAppointmentsDataArray([{
          subject: '',
          organization: '',
          location: '',
          description: '',
          file: '',
          reminderDate: '',
          reminderTime: '',
          scheduledDate: '',
          scheduledTime: '',
          type: 'create',
          id: 'new'
        }, ...data.data]);
        setTabCounts(data?.meta?.counts);
      },
      enabled: checkDateValidation(),
      refetchInterval: refetchingInterval,
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const statusObject = {
    ready: ['Ready', 'Called'],
    scheduled: ['Approved'],
    added: ['Draft', 'New', 'Postponed'],
    archive: ['Rejected', 'Done'],
  };

  const popover = (
    <Popover
      className='popover-ignore'
      placement={`${lang === 'ar' ? 'left' : 'right'}`}
    >
      <Popover.Header className='popover-ignore'>
        {t('selectFieldsText')}
      </Popover.Header>
      <Popover.Body className='popover-ignore' style={{ minWidth: '250px' }}>
        <div className='popover-ignore'>
          {fields.map((field) => {
            return (
              <div className='select_field_input popover-ignore' key={field.value} >
                <label className='popover-ignore'>
                  <input
                    type='checkbox'
                    className='popover-ignore'
                    value={!activeFields.includes(field.value)}
                    checked={activeFields.includes(field.value)}
                    onChange={(e) => {
                      if (e.target.value === 'true') {
                        setActiveFields([...activeFields, field.value]);
                        localStorage.setItem("activeFields", [...activeFields, field.value])
                      } else {
                        const arr = activeFields.filter(
                          (el) => el !== field.value
                        );
                        setActiveFields(arr);
                        localStorage.setItem("activeFields", arr)
                      }
                    }}
                  // ref={index === 0 ? popoverRef : null}
                  />
                  <span className='popover-ignore'> {field.label}</span>
                </label>
              </div>
            );
          })}
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      {loading && <CustomSpinner />}
      <Header refetch={refetch} setLoading={setLoading}></Header>
      <div className='appointmentsPage'>
        <div className='container'>
          <div className='appointmentPHeader'>
            <div className='left'>
              <p>{t('appointmentsText')}</p>
              {role === 'Secretary' ?
                <>
                  <Button
                    className='plusBtn popover-ignore'
                    variant='light'
                    ref={target}
                    onClick={() => {
                      setShow(!show);
                      if (!show)
                        popoverRef?.current?.focus();
                    }}
                  >
                    <img className='popover-ignore' src={SettingsIcon} alt='' />
                  </Button>
                  <Overlay
                    target={target.current}
                    show={show}
                    placement={`${lang === 'ar' ? 'left' : 'right'}`}
                  >
                    {({ arrowProps, ...props }) => (
                      <div
                        className='select_field_main'
                        id='popover-div'
                        {...props}
                        style={{
                          position: 'absolute',
                          padding: '10px',
                          marginTop: '110px',
                          borderRadius: 3,
                          ...props.style,
                        }}
                        // ref={popoverRef}
                        onBlur={() => {
                          setShow(false);
                        }}
                      >
                        {popover}
                      </div>
                    )}
                  </Overlay>
                  {/* <OverlayTrigger
                    trigger={'click'}
                    placement="right"
                    overlay={popover}
                  >
                    <Button variant="success">Hover me to see</Button>
                  </OverlayTrigger> */}
                </>
                : null}
            </div>
            <div className='right'>
              <form className='fWrapper'>
                <div className='formRow'>
                  <Form.Select
                    value={filterStatusId}
                    onChange={(e) => {
                      setFilterStatusId(e.target.value);
                      // getAppointmentsByStatusData.refetch();
                      setLoading(true);
                    }}
                  >
                    <option value='all'>{t('allText')}</option>
                    {getStatus?.data?.data?.map((val) => {
                      if (
                        statusObject?.[appointmentTab]?.includes(
                          val.name_translations?.en
                        )
                      ) {
                        return (
                          <option key={val.id} value={val.id}>
                            {val.name_translations?.[lang]}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </Form.Select>
                </div>
                <div className='formRow'>
                  <div className='csDatepicker'>
                    <img src={DatepickerIcon} alt='' />
                    <Flatpickr
                      value={startDate}
                      options={{ dateFormat: 'd/m/Y' }}
                      onChange={(e) => {
                        setStartDate(e[0]);
                        setLoading(true);
                        // getAppointmentsByStatusData.refetch();
                      }}
                    />
                  </div>
                </div>
                <div className='formRow'>
                  <div className='csDatepicker'>
                    <img src={DatepickerIcon} alt='' />
                    <Flatpickr
                      value={endDate}
                      options={{ dateFormat: 'd/m/Y', minDate: startDate }}
                      onChange={(e) => {
                        setEndDate(e[0]);
                        setLoading(true);
                        // getAppointmentsByStatusData.refetch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className='appointmentTabs'>
            <ul className='aTabs'>
              {appointmentStatusArray.map((val) => {
                return (
                  <li key={val.code}>
                    <button
                      className={`${val.code === appointmentTab ? 'active' : ''
                        }`}
                      onClick={() => {
                        setAppointmentTab(val.code);
                        setLoading(true);
                        setFilterStatusId('all');
                      }}
                    >
                      {t(val.locateCode)}
                      <span> ({TabCounts?.[val.code]})</span>
                    </button>
                  </li>
                );
              })}
            </ul>
            <div className='aTabsContant'>
              <div className='aTabsContantTarget'>
                {appointmentsDataArray &&
                  (appointmentsDataArray.length === 0 ? (
                    <>
                      <div className='noDataSec'>
                        <h2 className='title'>{t('noDataAvailableText')}</h2>
                        <img src={noDataImg} alt='' />
                      </div>
                    </>
                  ) : (
                    // appointmentsDataArray.map((appointment) => {
                    //   return (
                    // <AppointmentCard
                    //   appointment={appointment}
                    //   key={appointment.id}
                    //   refetch={refetch}
                    //   setRefetchingInterval={setRefetchingInterval}
                    // />
                    <AppointmentTable
                      appointmentsDataArray={appointmentsDataArray}
                      // key={appointmentsDataArray.id}
                      refetch={refetch}
                      setRefetchingInterval={setRefetchingInterval}
                      activeFields={activeFields}
                    />
                    //   );
                    // })
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
