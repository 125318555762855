import { globalFetchAPI } from './global-fetcher';
const BASE_URL = 'https://apitest.check-app.net/mo/api/v1';

export const getAppointment = (obj) => {
  const queryKey = obj.queryKey?.[1];
  if (queryKey.status_id && queryKey.status_id === 'all') {
    delete queryKey.status_id;
  }
  return globalFetchAPI({
    endPoint: BASE_URL + `/appointments`,
    queryParams: queryKey,
  }).then((res) => res.json());
};

export const getAppointmentStatus = async () => {
  return globalFetchAPI({
    endPoint: BASE_URL + `/appointment_statuses`,
    options: { userParams: false, languageAll: true },
  }).then((res) => res.json());
};

export const getAppointmentsByStatus = async (obj) => {
  const queryKey = obj.queryKey?.[1];

  const statusType = queryKey.appointmentStatus;
  const queryObject = {};

  Object.keys(queryKey).map((key) => {
    if (key === 'appointmentStatus') {
    } else if (key === 'status_id' && queryKey[key] !== 'all') {
      queryObject[key] = queryKey[key];
    } else if (key !== 'status_id') {
      queryObject[key] = queryKey[key];
    }
    return key;
  });

  return globalFetchAPI({
    endPoint: BASE_URL + `/appointments/${statusType}`,
    queryParams: queryObject,
  }).then((res) => res.json());
};

export const deleteAppointment = async (id) => {
  return globalFetchAPI({
    endPoint: BASE_URL + `/appointments/${id}`,
    method: 'DELETE',
  });
};

export const updateAppointment = async (data) => {
  return globalFetchAPI({
    endPoint: BASE_URL + `/appointments/${data['id']}`,
    method: 'PUT',
    body: data.requestBody,
    queryParams: {},
    options: { userParams: true, languageParams: true, languageAll: false },
    headers: {}
  })
    .then((res) => {
      if (res.ok) return res.json();
      else if (!res.ok) {
        throw new Error(res.status);
        // res.json().then((data) => {
        //   console.log(data); throw new Error(res.status)
        // })
      }
    })
    .catch((err) => {
      throw Error(err.message);
    });
};

export const createAppointment = async (data) => {
  return globalFetchAPI({
    endPoint: BASE_URL + '/appointments',
    method: 'POST',
    body: data,
    queryParams: {},
    options: { userParams: true, languageParams: true, languageAll: false },
    headers: {}
  })
    .then((res) => {
      if (res.ok) return res.json();
      else if (!res.ok) {
        throw new Error(res.status);
        // res.json().then((data) => {
        //   console.log(data); throw new Error(res.status)
        // })
      }
    })
    .catch((err) => {
      throw Error(err.message);
    });
};

export const getAppointmentDetails = (obj) => {
  const queryKey = obj.queryKey?.[1];
  return globalFetchAPI({
    endPoint: BASE_URL + `/appointments`,
    queryParams: { id: queryKey?.id },
  }).then((res) => res.json());
};
