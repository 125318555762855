import { Button } from 'react-bootstrap';
import React from 'react';

function CustomButton({ type = 'button', loading = false, text = '', className = '', otherProps }) {
  return (
    <Button
      type={type}
      className={`${className} loaderBtn ${loading ? 'loaderActive' : ''}`}
      disabled={loading}
      {...otherProps}
    >
      {text}
    </Button>
  );
}

export default CustomButton;
