import HeaderLogo from '../../assets/images/MayyarLogotrans.png';
import NotificationIcon from '../../assets/images/notificationIcon.svg';
import ArabicLangLogo from '../../assets/images/arabicLangLogo.png';
import EnglishLangLogo from '../../assets/images/englishLangLogo.png';
import { useContext, useState } from 'react';
import { LangSwitcher } from '../../context/lang-switcher';
import { useNavigate } from 'react-router-dom';
import { RoleSwitchModal } from '../../pages/appointments/components/role-switch-modal';
import { RoleSwitcher } from '../../context/role-switcher';
import { t } from '../../helpers/lang-helper';
import { AuthModal } from '../../pages/appointments/components/auth-modal';
export const Header = ({ refetch, setLoading }) => {
  const { lang, setLang } = useContext(LangSwitcher);
  const { role } = useContext(RoleSwitcher);
  const navigate = useNavigate();
  const [showRoleSwitch, setShowRoleSwitch] = useState(false);
  const [showAuthmodal, setShowAuthModal] = useState(false)

  const onChangeLanguage = (newLang) => {
    if (newLang !== lang) {
      const newPath = window.location.pathname.replace(
        `/${lang}/`,
        `/${newLang}/`
      );
      const search = (window.location.search);
      navigate(newPath + search);
      setLang(newLang);
      localStorage.setItem("language", newLang)
      setLoading && setLoading(true)
      refetch && refetch()
    }
  };

  return (
    <>
      <div className='header'>
        <div className='container'>
          <div className='logoWrapper'>
            <img src={HeaderLogo} alt='' />
          </div>
          <div className='rightWrapper'>
            <div className='lang'>
              <button
                className={`${lang === 'ar' && 'active'} en`}
                onClick={() => onChangeLanguage('en')}
              >
                <img src={EnglishLangLogo} alt='' /> EN
              </button>
              /
              <button
                className={`${lang === 'en' && 'active'} ar`}
                onClick={() => onChangeLanguage('ar')}
              >
                <img src={ArabicLangLogo} alt='' /> AR
              </button>
            </div>
            <p className='userName' onClick={() => setShowRoleSwitch(true)}>
              {role === 'Manager' ? t("managerUserText") : t("secretaryUserText")}
            </p>
            <button className='notifyBtn'>
              <img src={NotificationIcon} alt='' />
            </button>
          </div>
        </div>
      </div>
      <RoleSwitchModal show={showRoleSwitch} setShow={setShowRoleSwitch} refetch={refetch} setLoading={setLoading} />
      <AuthModal show={showAuthmodal} setShow={setShowAuthModal} refetch={refetch} setLoading={setLoading} />
    </>
  );
};
export default Header;
