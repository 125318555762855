import { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { RoleSwitcher } from '../../../context/role-switcher';
import { PW, USERTYPES } from '../../../helpers/constants';
import { t } from '../../../helpers/lang-helper';

export const RoleSwitchModal = ({ show, setShow, refetch, setLoading }) => {
  const { role, setRole } = useContext(RoleSwitcher);
  const [error, setError] = useState(false)
  const [pw, setPw] = useState('');


  const onSubmit = () => {
    if ((!pw || (role === 'Secretary' ? pw !== PW.m : pw !== PW.s))) {
      setError(true);
    } else {
      setShow(false)
      localStorage.setItem('userType', role === 'Secretary' ? USERTYPES.MANAGER : USERTYPES.SECRETARY)
      setError(false);
      setRole(role === 'Secretary' ? 'Manager' : 'Secretary');
      localStorage.setItem("role", role === 'Secretary' ? 'Manager' : 'Secretary')
      setLoading && setLoading(true)
      refetch && refetch()
    }
  }
  return (
    <Modal className='role-switcher' show={show} onHide={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>{t('areYouSureText')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('userModalMessage')}{' '}
        {role === 'Secretary' ? t('managerTextWithQues') : t('secretaryTextWithQues')}
        <div className="mt-4">
          <input
            type="password"
            placeholder="password"
            onChange={e => setPw(e.target.value)}
            onKeyUp={e => {
              if (e.key === "Enter") {
                onSubmit();
              }
            }}
          />
          {error && <span className="text-danger">Incorrect Password</span>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='modal-btn me-2'
          variant='secondary'
          onClick={() => setShow(false)}
        >
          {t('cancelButtonText')}
        </Button>
        <Button
          className='modal-btn'
          variant='primary'

          onClick={() => onSubmit()}
        >
          {t('okButtonText')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
