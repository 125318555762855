import { Button, Modal } from "react-bootstrap"
import { t } from "../../../helpers/lang-helper";


export const OpenPreviewModal = ({ openPreview, setOpenPreview, dataUrl }) => {

  const isAllowed = () => {
    let ext = '';
    if (dataUrl) {
      if (dataUrl.startsWith(['http'])) {
        const urlFrags = dataUrl.split('.');
        ext = urlFrags[urlFrags.length - 1];
      } else {
        // dataurl is base64 of uploaded file
        const dataExtFrags = dataUrl.split(';')
        if (dataExtFrags.length) {
          const dataExt = dataExtFrags[0].split(':');
          const extFrags = dataExt[dataExt.length - 1].split('/');
          if (extFrags.length > 1) {
            ext = extFrags[1];
          }
        }
      }
    }
    return ['pdf', 'png', 'jpg', 'jpeg'].includes(ext.toLowerCase()) ? ext : false;
  }

  const renderFile = () => {
    const ext = isAllowed();
    return (
      <>
        <div className="doc-view" style={{ width: '100%', height: '700px' }}>
          {['png', 'jpg', 'jpeg'].includes(ext) ? <img src={dataUrl} alt="organizer" width={"100%"} height="100%" /> :
            <object className="doc-view" width="100%" height="700px" data={`${dataUrl.replace('http://', 'https://')}#toolbar=0&navpanes=0`}>
              <div className="text-center h-full">{t('unabletoLoadFileText')}</div>
            </object>
          }
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal className='role-switcher ' show={openPreview} onHide={() => setOpenPreview(false)} size='xl'>
        <Modal.Body style={{ width: '100%', height: '700px' }}>
          {isAllowed() ? renderFile() : <div className="text-center">
            <p>{t('unabletoLoadFileText')}</p>
            <p>{t('clickHereText')}<a href={dataUrl} target="_blank" rel="noreferrer">{t('downloadFile')}</a></p>
          </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ margin: '25px' }}
            className='modal-btn'
            variant='primary'
            onClick={() => setOpenPreview(false)}>
            {t('cancelButtonText')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
