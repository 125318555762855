import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { PW, USERTYPES } from "../../../helpers/constants"
import { t } from "../../../helpers/lang-helper"

export const AuthModal = ({ show, setShow }) => {
  const [error, setError] = useState(false)
  const [pw, setPw] = useState('');

  const onSubmit = () => {
    if ((!pw || pw !== PW.s)) {
      setError(true);
    } else {
      setShow(false)
      localStorage.setItem('userType', USERTYPES.SECRETARY)
      setError(false);
    }
  }

  return (
    <Modal
      className='role-switcher'
      backdrop="static"
      keyboard={false}
      show={show} onHide={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>{t('secretaryUserText')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          {t('passwordText')}
        </span>
        <input
          className="mt-2"
          type="password"
          placeholder="password"
          onChange={e => setPw(e.target.value)}
          onKeyUp={e => {
            console.log(e);
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
        />
        {error && <span className="text-danger">{t('incorrectPasswordText')}</span>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='modal-btn me-2'
          variant='primary'
          onClick={() => onSubmit()}
        >
          {t('submitText')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}