import En from '../assets/lang/en.json';
import Ar from '../assets/lang/ar.json';

const langContent = {
    en: En,
    ar: Ar
};


export const t = (arg) => {
    return langContent[getCurrentLang()][arg] ?? '';
}

export const getCurrentLang = () => {
    const path = window.location.pathname;
    const lang = path.split('/')[1];
    if (['en', 'ar'].includes(lang)) {
        return lang;
    }
    return 'en';
}

export const getRouteLink = (path) => {
    return '/' + getCurrentLang() + path;
}