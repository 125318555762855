import { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { LangSwitcher } from './context/lang-switcher';
import { AppointmentForm } from './pages/appointments/appointment-form/appointment-form';
import { Appointments } from './pages/appointments/appointments';
import { AuthModal } from './pages/appointments/components/auth-modal';

export const AppRoutes = () => {
  const { lang } = useContext(LangSwitcher);
  const [showAuth, setShowAuth] = useState(true);
  useEffect(() => {
    if (lang === 'en') {
      document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr');
    }
    if (lang === 'ar') {
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl');
    }
    return function cleanup() { };
  }, [lang]);

  const MainRouteRender = () => {
    if (localStorage.getItem('userType')) {
      return <Navigate to={'/' + lang + '/appointments'} replace />;
    } else {
      return null;
    }
  }

  return (
    <BrowserRouter>
      {localStorage.getItem('userType') ?
        <Routes>
          <Route
            index
            element={<MainRouteRender />}
          />
          <Route path={'/' + lang} element={<Outlet />}>
            <Route index element={<Navigate to={'appointments'} replace />} />
            <Route path='appointments' element={<Outlet />}>
              <Route index element={<Appointments />} />
              <Route
                path='create'
                element={<AppointmentForm formType={'create'} />}
              />
              <Route
                path='update'
                element={<AppointmentForm formType={'update'} />}
              />
            </Route>
          </Route>
          <Route
            path='*'
            element={<Navigate to={'/' + lang + '/appointments'} replace />}
          />
        </Routes>
        : <AuthModal show={showAuth} setShow={setShowAuth} />}
    </BrowserRouter>
  );
};
