import React from 'react'
import siteLoaderImg from "../../assets/images/siteLoader.svg";
function CustomSpinner() {
  return (
    <div className="siteLoader active">
      <div className="siteLoaderImg">
        <img src={siteLoaderImg} alt="" />
      </div>
    </div>
  )
}

export default CustomSpinner