import { createContext, useEffect, useState } from "react";
import { getCurrentLang } from "../helpers/lang-helper";


export const LangSwitcher = createContext({
    lang: localStorage.getItem("language") ?? 'en',
    setLang: () => { }
});

export const LangSwitcherProvider = ({ children }) => {
    const [lang, setLang] = useState(getCurrentLang());
    useEffect(() => {
        localStorage.setItem('language', lang);
    }, [lang]);

    return <LangSwitcher.Provider value={{ lang, setLang }}>{children}</LangSwitcher.Provider>;
}
