import { createContext, useEffect, useState } from "react";

export const RoleSwitcher = createContext({
  role: localStorage.getItem('role') ?? 'Secretary',
  setRole: () => { }
});

export const RoleSwitcherProvider = ({ children }) => {
  const defaultValue = localStorage.getItem('role') ?? 'Secretary';
  const [role, setRole] = useState(defaultValue);

  useEffect(() => {
    localStorage.setItem('role', role);
  }, [role]);

  return <RoleSwitcher.Provider value={{ role, setRole }}>{children}</RoleSwitcher.Provider>;
}

