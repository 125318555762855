import { useMutation } from '@tanstack/react-query';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  updateAppointment,
  deleteAppointment,
  createAppointment,
} from '../../../apis/appointments';
import CustomButton from '../../../components/customButton/custom-button';
import { getRouteLink, t } from '../../../helpers/lang-helper';
import { Table } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import { LangSwitcher } from '../../../context/lang-switcher';
import { OpenPreviewModal } from './open-preview-modal';
const initialValues = {
  subject: '',
  organization: '',
  location: '',
  details: '',
  file: '',
  reminderDate: '',
  reminderTime: '',
  scheduledDate: '',
  scheduledTime: '',
};
export default function AppointmentTable({
  appointmentsDataArray,
  refetch,
  setRefetchingInterval,
  activeFields,
}) {
  const navigate = useNavigate();
  const deleteMutation = useMutation(deleteAppointment);
  const createAppointmentMutation = useMutation(createAppointment);
  const updateAppointmentMutation = useMutation(updateAppointment);
  const [btnLoading, setBtnLoading] = useState({});
  const [submittedIds, setSubmittedIds] = useState([]);
  const [tableType,] = useState('create');
  const [editTableData, setEditTableData] = useState([])
  const { lang } = useContext(LangSwitcher);

  const [tableFormData, setTableFormData] = useState({
    new: { ...initialValues, id: 'new' }
  });
  const [updatingIds, setUpdatingIds] = useState([]);
  const [tableRowVisible, setTableRowVisible] = useState(false)
  const [dataUrl, setDataUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [openPreview, setOpenPreview] = useState();
  const reader = new FileReader();

  reader.addEventListener("load", () => {
    // convert image file to base64 string
    setDataUrl(reader.result);
  }, false);

  useEffect(() => {
    const arr = Object.keys(tableFormData).filter((el) => el !== 'new')
    if (arr.length > 0) {
      setRefetchingInterval(false);
    } else {
      setRefetchingInterval(5000);
    }
  }, [tableFormData]); // eslint-disable-line

  useEffect(() => {
    if (tableType === 'create') {
      setTableRowVisible(true);
    }
  }, []); //eslint-disable-line

  const handleDelete = async (id, code) => {
    deleteMutation.mutate(id, {
      onSuccess: (data) => {
        toast.success(t('successfullyDeletedMessage'));
        // setEditTableData({ [id]: true })
        setEditTableData([...editTableData, id])
        navigate(getRouteLink('/appointments'));
      },
      onError: (error, variables, context) => {
        // console.log(error, variables, context)
      },
      onSettled: () => {
        setBtnLoading({ ...btnLoading, [id]: { [code]: false } });
        refetch();
      },
    });
  };

  const hasAnyErrors = (formData) => {
    return !formData.subject || !formData.organization || !formData.location || !formData.details || !formData.scheduledTime || !formData.scheduledDate;
  }

  const handleSubmit = async (e, id) => {
    setSubmittedIds(ids => {
      const temp = [...ids];
      if (!temp.includes(id)) {
        temp.push(id);
      }
      return temp;
    })
    e.preventDefault();
    e.stopPropagation()
    const formData = tableFormData[id];
    const errors = hasAnyErrors(formData);
    if (!errors) {
      const scheduledDateFormat = moment(formData.scheduledDate).format(
        'YYYY-MM-DD'
      );
      const reminderDateFormat = moment(formData.reminderDate).format(
        'YYYY-MM-DD'
      );
      const scheduledTimeFormat = moment(formData.scheduledTime).format(
        'HH:mm'
      );
      const reminderTimeFormat = moment(formData.reminderTime).format(
        'HH:mm'
      );
      const fData = new FormData();
      fData.append('subject', formData.subject);
      fData.append('details', formData.details);
      fData.append('scheduled_date', `${scheduledDateFormat} ${scheduledTimeFormat}:00`);
      fData.append('organization', formData.organization);
      fData.append('location', formData.location);
      if (formData.file_data) {
        fData.append('file', formData.file_data);
      } else if (formData.file_data === null) {
        fData.append('file', '')
      } else if (id === 'new') {
        fData.append('file', '');
      }
      // fData.append('reminder', null);
      // const requestBody = {
      //   subject: formData.subject,
      //   details: formData.details,
      //   scheduled_date: `${scheduledDateFormat} ${scheduledTimeFormat}:00`,
      //   organization: formData.organization,
      //   location: formData.location,
      //   file: formData.file,
      //   reminder: null
      // };
      if (
        formData.reminderDate &&
        formData.reminderTime
      ) {
        // requestBody.reminder = `${reminderDateFormat} ${reminderTimeFormat}:00`;
        fData.append('reminder', `${reminderDateFormat} ${reminderTimeFormat}:00`);
      }
      if (id === 'new') {
        // handleCreateMutation(requestBody, id);
        handleCreateMutation(fData, id);
      } else {
        // handleEditMutation(requestBody, id);
        handleEditMutation(fData, id);
      }
    }
    // setValidated(true);
  };

  const handleEditMutation = (requestBody, id) => {
    requestBody.append('action', 'edit');
    setUpdatingIds(ids => [...ids, id]);
    updateAppointmentMutation.mutate(
      { requestBody, id: id },
      {
        onSuccess: (data) => {
          setUpdatingIds(ids => {
            const temp = [...ids];
            temp.splice(temp.indexOf(id), 1)
            return temp;
          });
          setSubmittedIds(ids => {
            const temp = [...ids];
            temp.splice(temp.indexOf(id), 1)
            return temp;
          })
          // setEditTableData({ [id]: false });
          const tempFormData = { ...tableFormData };
          delete tempFormData[id];
          setTableFormData(tempFormData);
          navigate(getRouteLink('/appointments?tab=added'));
          setTableRowVisible(true);
          toast.success(data?.meta?.detail);
        },
        onError: (error, variables, context) => {
          setUpdatingIds(ids => {
            const temp = [...ids];
            temp.splice(temp.indexOf(id), 1)
            return temp;
          });
          setSubmittedIds(ids => {
            const temp = [...ids];
            temp.splice(temp.indexOf(id), 1)
            return temp;
          })
          // console.log(error, variables, context)
        },
        onSettled: () => {
          refetch();
        },
      }
    );
  };

  const handleCreateMutation = (requestBody, id) => {
    setUpdatingIds(ids => [...ids, id]);
    createAppointmentMutation.mutate(requestBody, {
      onSuccess: (data) => {
        setUpdatingIds(ids => {
          const temp = [...ids];
          temp.splice(temp.indexOf(id), 1)
          return temp;
        });
        setSubmittedIds(ids => {
          const temp = [...ids];
          temp.splice(temp.indexOf(id), 1)
          return temp;
        })
        toast.success(data?.meta?.detail);

        const tempFormData = { ...tableFormData };
        tempFormData['new'] = { ...initialValues };
        setTableFormData(tempFormData);
        navigate(getRouteLink('/appointments?tab=added'));
      },
      onError: (error, variables, context) => {
        setUpdatingIds(ids => {
          const temp = [...ids];
          temp.splice(temp.indexOf(id), 1)
          return temp;
        });
        setSubmittedIds(ids => {
          const temp = [...ids];
          temp.splice(temp.indexOf(id), 1)
          return temp;
        })
        // console.log(error, variables, context)
      },
      onSettled: () => {
        refetch();
      },
    });
  };

  const handleAppointmentStatus = async (id, code, appointment) => {
    const fData = new FormData();
    fData.append('subject', appointment.subject);
    fData.append('details', appointment.details);
    fData.append('scheduled_date', appointment.scheduled_date);
    fData.append('organization', appointment.organization);
    fData.append('location', appointment.location);
    if (appointment.reminder) {
      fData.append('reminder', appointment.reminder);
    }
    fData.append('action', code);
    // const requestBody = {
    //   subject: appointment.subject,
    //   details: appointment.details,
    //   scheduled_date: appointment.scheduled_date,
    //   organization: appointment.organization,
    //   location: appointment.location,
    //   reminder: appointment.reminder,
    //   action: code,
    // };
    updateAppointmentMutation.mutate(
      { requestBody: fData, id },
      {
        onSuccess: (data) => {
          toast.success(data?.meta?.detail);
          navigate(getRouteLink('/appointments'));
        },
        onError: (error, variables, context) => {
          // console.log(error, variables, context)
        },
        onSettled: () => {
          setBtnLoading({ ...btnLoading, [id]: { [code]: false } });
          refetch();
        },
      }
    );
  };


  const handleClick = (options) => {
    const event = options.e;
    event.preventDefault();
    event.stopPropagation();

    if (options.code !== 'edit') {
      setBtnLoading({ ...btnLoading, [options.id]: { [options.code]: true } });
    }

    if (options.code === 'delete') {
      handleDelete(options.id, options.code);
    } else if (options.code === 'edit') {
      const tempTableData = { ...tableFormData };
      if (!tempTableData[options.id]) {
        const editData = { ...options.appointment };
        editData.scheduledDate = editData?.scheduled_date
        editData.scheduledTime = editData?.scheduled_date
        if (editData.reminder) {
          editData.reminderDate = editData?.reminder
          editData.reminderTime = editData?.reminder
        }
        tempTableData[options.id] = editData;
        setTableFormData(tempTableData);
      }
      // handleEdit(options.id, options.code);
    } else {
      handleAppointmentStatus(options.id, options.code, options.appointment);
    }
  };

  const getFileName = (file) => {
    if (file) {
      const frags = file.split('/');
      return frags[frags.length - 1];
    }
    return ''
  }

  const isRemotePath = (file) => {
    return file && (file.startsWith('http://') || file.startsWith('https://'));
  }

  return (
    <>
      <div>
        <div className='table_style_1'>
          {tableRowVisible ?
            <PerfectScrollbar style={{ borderRadius: '20px' }} key={lang}>
              <Table bordered hover>
                <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                  <tr style={{ borderWidth: '0px' }}>
                    <th>{t('idText')}</th>
                    {activeFields.includes('subject') && <th style={{ minWidth: '200px' }}>{t('subjectText')}</th>}
                    {activeFields.includes('description') && <th className='tbl_details'>{t('descriptionText')}</th>}
                    {activeFields.includes('organization') && <th style={{ minWidth: '200px' }}>{t('organizationText')}</th>}
                    {activeFields.includes('location') && <th style={{ minWidth: '200px' }}>{t('locationText')}</th>}
                    {activeFields.includes('file') && <th style={{ minWidth: '200px' }}>{t('fileText')}</th>}
                    {activeFields.includes('schedule_date') && <th style={{ minWidth: '200px' }}>{t('dateText')}</th>}
                    {activeFields.includes('schedule_date') && <th style={{ minWidth: '150px' }}>{t('timeText')}</th>}
                    {activeFields.includes('reminder') && <th style={{ minWidth: '200px' }}>{t('reminderDateText')}</th>}
                    {activeFields.includes('reminder') && <th style={{ minWidth: '150px' }}>{t('reminderTimeText')}</th>}
                    {activeFields.includes('status') && <th>{t('statusText')}</th>}
                    {activeFields.includes('actions') && <th>{t('actionsText')}</th>}
                  </tr >
                </thead >
                <tbody>
                  {appointmentsDataArray.map((appointment, index) => {
                    return (
                      // tableFormData[appointment?.id] === true || appointment?.type === 'create' ?
                      <tr key={`${appointment.id}_${updatingIds.includes(appointment.id)}`}>
                        {tableFormData[appointment.id] ?
                          <>
                            <td>{appointment?.id}</td>
                            {activeFields.includes('subject') &&
                              <td>
                                <input
                                  placeholder='Subject'
                                  defaultValue={tableFormData[appointment.id].subject}
                                  onChange={(e) => {
                                    setTableFormData(data => {
                                      const temp = { ...data };
                                      temp[appointment.id]['subject'] = e.target.value
                                      return temp;
                                    })
                                  }}
                                />

                                {!tableFormData[appointment.id]["subject"] && submittedIds.includes(appointment.id) && <div className='custom-invalid-feedback'>
                                  {t('pleaseAddSubject')}
                                </div>}
                              </td>}
                            {activeFields.includes('description') &&
                              <td className='tbl_details'>
                                <input
                                  placeholder='Description'
                                  defaultValue={tableFormData[appointment.id].details}
                                  onChange={(e) => {
                                    setTableFormData(data => {
                                      const temp = { ...data };
                                      temp[appointment.id]['details'] = e.target.value
                                      return temp;
                                    })
                                  }}
                                />
                                {(!tableFormData[appointment.id]["details"] && submittedIds.includes(appointment.id)) && <div className='custom-invalid-feedback'>
                                  {t('pleaseAddDescription')}
                                </div>}
                              </td>}
                            {activeFields.includes('organization') &&
                              <td >
                                <input
                                  placeholder='Organization'
                                  defaultValue={tableFormData[appointment.id].organization}
                                  onChange={(e) => {
                                    setTableFormData(data => {
                                      const temp = { ...data };
                                      temp[appointment.id]['organization'] = e.target.value
                                      return temp;
                                    })
                                  }}
                                />
                                {(!tableFormData[appointment.id]["organization"] && submittedIds.includes(appointment.id)) && <div className='custom-invalid-feedback'>
                                  {t('pleaseAddOrganization')}
                                </div>}
                              </td>}
                            {activeFields.includes('location') &&
                              <td>
                                <input
                                  placeholder='Location'
                                  defaultValue={tableFormData[appointment.id].location}
                                  onChange={(e) => {
                                    setTableFormData(data => {
                                      const temp = { ...data };
                                      temp[appointment.id]['location'] = e.target.value
                                      return temp;
                                    })
                                  }}
                                />
                                {(!tableFormData[appointment.id]["location"] && submittedIds.includes(appointment.id)) && <div className='custom-invalid-feedback'>
                                  {t('pleaseAddLocation')}
                                </div>}
                              </td>
                            }
                            {activeFields.includes('file') &&
                              <td>
                                {!tableFormData[appointment.id]['file'] ?
                                  <div className='role-switcher'>
                                    <input
                                      type="file"
                                      accept="application/pdf"
                                      placeholder='file'
                                      onChange={(e) => {
                                        // if (e.target.files[0].type === 'application/pdf') {
                                        setTableFormData(data => {
                                          const temp = { ...data };
                                          temp[appointment.id]['file_data'] = e.target.files[0]
                                          temp[appointment.id]['file'] = e.target.files[0]['name'];
                                          return temp;
                                        })
                                        if (e.target.files[0]) {
                                          reader.readAsDataURL(e.target.files[0]);
                                        }
                                        // }
                                      }}
                                      id={`file_${index}`}
                                      className="d-none"
                                    />
                                    <label for={`file_${index}`} className="cursor-pointer">
                                      {t('uploadFileText')}
                                    </label>
                                  </div>
                                  : <div className='d-flex'>
                                    <span className='me-5 file-name cursor-pointer' onClick={() => {
                                      setOpenPreview(true);
                                      setPreviewUrl(appointment.id === 'new' || (appointment.id !== 'new' && tableFormData[appointment.id]['file_data']) ? dataUrl : tableFormData[appointment.id]['file']);
                                    }}>
                                      {getFileName(tableFormData[appointment.id]['file'])}
                                    </span>
                                    <strong className='cursor-pointer' onClick={(e) => {
                                      tableFormData[appointment.id]['file'] &&
                                        setTableFormData(data => {
                                          const temp = { ...data };
                                          temp[appointment.id]['file_data'] = null
                                          temp[appointment.id]['file'] = null;
                                          return temp;
                                        })
                                    }}>X</strong>
                                  </div>
                                }
                              </td>
                            }
                            {activeFields.includes('schedule_date') &&
                              <td>
                                <Flatpickr
                                  className={`${updatingIds.includes(appointment.id) && !tableFormData[appointment.id].scheduledDate
                                    ? 'picker-invalid'
                                    : ''
                                    }`}
                                  placeholder='dd/mm/yyyy'
                                  defaultValue={tableFormData[appointment.id].scheduledDate ? moment(tableFormData[appointment.id].scheduledDate).format('DD-MM-YYYY') : null}
                                  required
                                  options={{ dateFormat: 'd/m/Y' }} //minDate: 'today'
                                  onChange={(e) => {
                                    setTableFormData(data => {
                                      const temp = { ...data };
                                      temp[appointment.id]['scheduledDate'] = e[0]
                                      return temp;
                                    })
                                  }}
                                />
                                {(!tableFormData[appointment.id]["scheduledDate"] && submittedIds.includes(appointment.id)) && <div className='custom-invalid-feedback'>
                                  {t('pleaseAddDate')}
                                </div>}
                              </td>
                            }
                            {activeFields.includes('schedule_date') &&
                              <td>
                                <Flatpickr
                                  className={`${updatingIds.includes(appointment.id) && !tableFormData[appointment.id].scheduledTime
                                    ? 'picker-invalid'
                                    : ''
                                    }`}
                                  defaultValue={tableFormData[appointment.id].scheduledTime ? moment(tableFormData[appointment.id].scheduledTime).format('HH:mm') : null}
                                  placeholder='HH:mm'
                                  required
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: 'H:i',
                                    time_24hr: true,
                                  }}
                                  onChange={(e) => {
                                    setTableFormData(data => {
                                      const temp = { ...data };
                                      temp[appointment.id]['scheduledTime'] = e[0]
                                      return temp;
                                    })
                                  }}
                                />
                                {(!tableFormData[appointment.id]["scheduledTime"] && submittedIds.includes(appointment.id)) && <div className='custom-invalid-feedback'>
                                  {t('pleaseAddTime')}
                                </div>}
                              </td>
                            }
                            {activeFields.includes('reminder') &&
                              <td>
                                <Flatpickr
                                  className={`${updatingIds.includes(appointment.id) &&
                                    tableFormData[appointment.id].reminderTime &&
                                    !tableFormData[appointment.id].reminderDate
                                    ? 'picker-invalid'
                                    : ''
                                    }`}
                                  placeholder='dd/mm/yyyy'
                                  required
                                  options={{
                                    dateFormat: 'd/m/Y',
                                    // maxDate: formValues.scheduledDate,
                                    // minDate: 'today',
                                  }}
                                  onChange={(e) => {
                                    setTableFormData(data => {
                                      const temp = { ...data };
                                      temp[appointment.id]['reminderDate'] = e[0]
                                      return temp;
                                    })
                                  }}
                                  defaultValue={tableFormData[appointment.id].reminderDate ? moment(tableFormData[appointment.id].reminderDate).format('DD-MM-YYYY') : null}
                                />
                              </td>
                            }
                            {activeFields.includes('reminder') &&
                              <td>
                                <Flatpickr
                                  className={`${updatingIds.includes(appointment.id) &&
                                    !tableFormData[appointment.id].reminderTime &&
                                    tableFormData[appointment.id].reminderDate
                                    ? 'picker-invalid'
                                    : ''
                                    }`}
                                  defaultValue={tableFormData[appointment.id].reminderTime ? moment(tableFormData[appointment.id].reminderTime).format('HH:mm') : null}
                                  required
                                  placeholder='HH:mm'
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: 'H:i',
                                    time_24hr: true,
                                  }}
                                  onChange={(e) => {
                                    setTableFormData(data => {
                                      const temp = { ...data };
                                      temp[appointment.id]['reminderTime'] = e[0]
                                      return temp;
                                    })
                                  }}
                                />
                              </td>
                            }
                            {activeFields.includes('status') &&
                              <td>
                                <span className={`${tableType === 'edit' ? 'statusBadge code' + appointment?.status?.id : ''}`}>
                                  {appointment?.status ? appointment?.status?.name : '-'}
                                </span>
                              </td>
                            }
                            {activeFields.includes('actions') &&
                              <td className='apBoxBtns'>
                                {appointment.id !== 'new' ?
                                  <>
                                    <CustomButton
                                      type='submit'
                                      className='apBoxBtn'
                                      loading={updatingIds.includes(appointment.id)}
                                      text={t('updateText')}
                                      otherProps={{
                                        onClick: (e) => {
                                          handleSubmit(e, appointment.id)
                                        }
                                      }}
                                    />
                                    <CustomButton
                                      type='button'
                                      className='apBoxBtn'
                                      text={t('cancelButtonText')}
                                      otherProps={{
                                        onClick: () => {
                                          const tempItems = { ...tableFormData };
                                          delete tempItems[appointment.id];
                                          setSubmittedIds(submittedIds.filter(item => item !== appointment.id));
                                          setUpdatingIds(updatingIds.filter(item => item !== appointment.id));
                                          setTableFormData(tempItems);
                                        },
                                      }}
                                    />
                                  </>
                                  : <CustomButton
                                    type='submit'
                                    className='apBoxBtn'
                                    loading={updatingIds.includes(appointment.id)}
                                    text={t('createText')}
                                    otherProps={{
                                      onClick: (e) => {
                                        // setSubmittingError(true)
                                        handleSubmit(e, 'new')
                                      }
                                    }}
                                  />}
                              </td>
                            }
                          </> :
                          <>
                            <td>{appointment?.id}</td>
                            {activeFields.includes('subject') && <td>{appointment?.subject}</td>}
                            {activeFields.includes('description') && <td >{appointment?.details}</td>}
                            {activeFields.includes('organization') && <td >{appointment?.organization}</td>}
                            {activeFields.includes('location') && <td>{appointment?.location}</td>}
                            {activeFields.includes('file') && <td>{isRemotePath(appointment?.file) ? <span
                              className='file-name cursor-pointer'
                              onClick={() => {
                                setOpenPreview(true);
                                setPreviewUrl(appointment?.file);
                              }}>{getFileName(appointment?.file)}</span> : appointment?.file}</td>}
                            {activeFields.includes('schedule_date') && <td>{appointment?.scheduled_date ? moment(appointment?.scheduled_date).format('YYYY-MM-DD') : null}</td>}
                            {activeFields.includes('schedule_date') && <td>{appointment?.scheduled_date ? moment(appointment?.scheduled_date).format('HH:mm') : null}</td>}
                            {activeFields.includes('reminder') && <td>{appointment?.reminder ? moment(appointment?.reminder).format('YYYY-MM-DD') : null}</td>}
                            {activeFields.includes('reminder') && <td>{appointment?.reminder ? moment(appointment?.reminder).format('HH:mm') : null}</td>}
                            {activeFields.includes('status') &&
                              <td>
                                <span className={`statusBadge code${appointment?.status?.id}`}>
                                  {appointment?.status?.name}
                                </span>
                              </td>
                            }
                            {activeFields.includes('actions') &&
                              <td className='apBoxBtns'>{appointment?.buttons?.map((val) => {
                                return (
                                  <CustomButton
                                    key={val.code}
                                    className='apBoxBtn'
                                    type='button'
                                    otherProps={{
                                      onClick: (e) => {
                                        handleClick({ code: val.code, e, id: appointment.id, appointment });
                                      },
                                    }}
                                    text={val.name}
                                    loading={btnLoading?.[appointment.id]?.[val.code]}
                                  />
                                );
                              })}</td>
                            }
                          </>}
                      </tr>)
                  })}
                </tbody>
                <OpenPreviewModal openPreview={openPreview} setOpenPreview={setOpenPreview} dataUrl={previewUrl} />
              </Table >
            </PerfectScrollbar> : null}
        </div >
      </div>
    </>
  );
}
