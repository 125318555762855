// export const globalFetcher = (endPoint, requestType = 'GET', body = {}, queryParams = "") => {
//   return fetch()
// }

export const globalFetcher = (endPoint, requestType = 'GET', body = {}, queryParams = {}) => {
  const requestOptions = {
    method: requestType,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  if (Object.keys(body).length) {
    requestOptions.body = JSON.stringify(body)
  }

  const url = new URL(endPoint)
  if (Object.keys(queryParams).length) {
    Object.keys(queryParams).map((key) => {
      return url.searchParams.set(key, queryParams?.[key])
    })
  }
  return fetch(url.href, requestOptions)
}

export const globalFetchAPI = ({ endPoint, method = 'GET', body = {}, queryParams = {}, options = { userParams: true, languageParams: true, languageAll: false }, headers = { 'Content-Type': 'application/json' } }) => {

  const User = localStorage.getItem("role").toLowerCase() ?? 'Secretary'.toLowerCase();

  const requestOptions = {
    method,
    headers,
  }

  if (headers["Content-Type"] === 'application/json') {
    if (Object.keys(body).length) {
      requestOptions.body = JSON.stringify(body)
    }
  } else {
    requestOptions.body = body;
  }

  const url = new URL(endPoint)

  if (options.userParams) {
    url.searchParams.set("user", User)
  }

  const currentLang = localStorage.getItem("language").toLowerCase() ?? 'en';
  // options: { languageParams: false },
  if (options.languageParams) {
    url.searchParams.set('lang', currentLang);
  }
  // options: { languageAll: true },
  if (options.languageAll) {
    url.searchParams.set('lang', 'all');
  }
  if (Object.keys(queryParams).length) {
    Object.keys(queryParams).map((key) => {
      return url.searchParams.set(key, queryParams?.[key])
    })
  }
  return fetch(url.href, requestOptions)
}